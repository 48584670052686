import React, { useState, useEffect } from "react";
import NearestLocation from "../nearestLocation";
import NearestDentist from "../nearestDentist";
import WPLink from "../wpLink";
import Submenu from "../submenu";
import FixedImage from "../fixedImage";
import { Link } from "gatsby";
import useModalState from '../../helpers/modalState';
import * as styleSheet from "./index.module.scss"

const DesktopNav = ({mainNavigationSettings, services, locations, dentists, arrowBlue, arrowGreen, arrowRedLocal, arrowGrayLocal, locationsPage = null, teamPage = null, isHomepage = false }) => {
	const {
		showLocationsInMenu,
		showServicesInMenu,
 		showTeamInMenu,
		servicesMegamenu: {
		  featuredServices,
		  servicesList
		},
		servicesMenuTitle,
		navCta,
		links,
		locationsMenuTitle,
		teamMenuTitle,
	} = mainNavigationSettings;


	const [showAppointmentModal, setShowAppointmentModal] = useModalState();

	function showModal(){
		  if( !showAppointmentModal.modalOpen ){
                setShowAppointmentModal({
                    modalOpen: true, 
                    zipcode: showAppointmentModal.zipcode,
                    greyfinchId: showAppointmentModal.greyfinchId,
                    showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                    callCenterModalOpen: showAppointmentModal.callCenterModalOpen
                });
		  }
	  }
  
	function handleApptKeyDown(e) {
	  if (e.keyCode === 13 || e.keyCode === 32) {
              setShowAppointmentModal({
                modalOpen: true, 
                zipcode: showAppointmentModal.zipcode,
                greyfinchId: showAppointmentModal.greyfinchId,
                showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                callCenterModalOpen: showAppointmentModal.callCenterModalOpen
            });
		  }
	}


	const [ servicesOpen, setServicesOpen ] = useState(false);
	const [ locationsOpen, setLocationsOpen ] = useState(false);
	const [ teamOpen, setTeamOpen ] = useState(false);

	const [ servicesLinkActive, setServicesLinkActive ] = useState(false);
	const [ locationsLinkActive, setLocationsLinkActive ] = useState(false);
	const [ teamLinkActive, setTeamLinkActive ] = useState(false);

	const toggleServices = () => {
		const toggled = !servicesOpen;
		setLocationsOpen(false);
		setTeamOpen(false);
		setServicesOpen(toggled);
	};

	const toggleLocations = () => {
		const toggled = !locationsOpen;
		setServicesOpen(false);
		setTeamOpen(false);
		setLocationsOpen(toggled);
	};

	const toggleTeam = () => {
		const toggled = !teamOpen;
		setServicesOpen(false);
		setLocationsOpen(false);
		setTeamOpen(toggled);
	};

	useEffect( () => {
		const setActiveLinks = () => {
			if( window.location.pathname.includes("/location/") || (locationsPage !== null && locationsPage.nodes.length > 0 && window.location.pathname.includes(locationsPage.nodes[0].link) ) ){
				setLocationsLinkActive(true);
			}

			if( window.location.pathname.includes("/service/") ){
				setServicesLinkActive(true);
			}

			if( window.location.pathname.includes("/dentist/") || (teamPage !== null && teamPage.nodes.length > 0 && window.location.pathname.includes(teamPage.nodes[0].link) ) ){
				setTeamLinkActive(true);
			}
		};

		if( typeof window !== undefined ){
			setActiveLinks();
		}
	}, [locationsPage, teamPage]);



  
	useEffect( () => {
		dentists.sort(function(a, b){
			var keyA = a.ACFDentist.lastName,
				keyB = b.ACFDentist.lastName;
			if(keyA < keyB) return -1;
			if(keyA > keyB) return 1;
			return 0;
		});

	}, [dentists])


  	return (
		<nav className={styleSheet.DesktopNav} role="navigation">
			<ul className={styleSheet.navlist}>
			{ showLocationsInMenu && (
					<li className={styleSheet.navlistitem}>
						<button 
							className={locationsOpen ? styleSheet.togglelinkactive : locationsLinkActive ? styleSheet.toggleLinkNavActive : styleSheet.togglelink} 
							onClick={(event)=>{
								event.preventDefault();
								toggleLocations();
						}}>{locationsMenuTitle}</button>
						<button aria-label="close modal" className={  locationsOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { toggleLocations(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleLocations(); }}} tabIndex={0}></button> 
						<Submenu showIf={locationsOpen} setInsuranceIsOpen={setLocationsOpen} passedClass={styleSheet.locations}>
							<div className={styleSheet.inner}>
								<div className={styleSheet.left}>
									<span className={styleSheet.heading}>Featured Location</span>
									<NearestLocation locations={locations} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} />
								</div>
								<div className={styleSheet.right}>
									<span className={styleSheet.heading}>More nearby locations</span>
									<ul className={styleSheet.list}>
										{ locations && locations.map((location, index) => {
											return (
												<li key={`lstloc-'${location.id}`} className={styleSheet.listitem}>
													<Link to={location.link} className={styleSheet.link}>
														<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
														<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
														{location.ACFLocation.menuTitle && <span className={styleSheet.altmenutitle}>{location.ACFLocation.menuTitle}</span>}
													</Link>	
												</li>
											)
										})}
									</ul>
									<WPLink link={{target: '', title: 'See all locations', url: '/locations/'}} passedClass={styleSheet.seeAll} passedIconClass={styleSheet.icon} icon={arrowGreen} />
								</div>
							</div>
						</Submenu>
					</li>
				)}
				{ showServicesInMenu && (
					<li className={styleSheet.navlistitem}>
						<button 
							className={servicesOpen ? styleSheet.togglelinkactive : servicesLinkActive ? styleSheet.toggleLinkNavActive : styleSheet.togglelink} 
							onClick={(event)=>{
								event.preventDefault();
								toggleServices();
						}}>{servicesMenuTitle}</button>
						<button aria-label="close modal" className={  servicesOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { toggleServices(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleServices(); }}} tabIndex={0}></button> 
						<Submenu showIf={servicesOpen} setInsuranceIsOpen={setServicesOpen} passedClass={styleSheet.services}>
							<div className={styleSheet.inner}>
								<div className={styleSheet.left}>
									<span className={styleSheet.heading}>Featured Services</span>
									<div className={styleSheet.servicescontainer}>
										{ featuredServices && featuredServices.map((service, index) => {
											return (
												<div key={`ftsrvc-'${service.id}`} className={styleSheet.featuredservice}>
													<Link to={service.link}><h2 className={styleSheet.title}>{service.title } <FixedImage passedClass={styleSheet.icon} image={arrowGreen} /></h2></Link>
													{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
												</div>
											)
										})}
									</div>
								</div>
								<div className={styleSheet.right}>
									<span className={styleSheet.heading}>Find more services.</span>
									<ul className={styleSheet.list}>
										{ servicesList ? servicesList.map((service, index) => {
											return (
												<li key={`lstsrvc-'${service.id}`} className={styleSheet.listitem}>
													<Link to={service.link} className={styleSheet.link}>
														<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
														<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
													</Link>	
												</li>
											)
										}) : services.map((service, index) => {
											return (
												<li key={`lstsrvc-'${service.id}`} className={styleSheet.listitem}>
													<Link to={service.link} className={styleSheet.link}>
														<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
														<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
													</Link>	
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</Submenu>
					</li>
				)}

				{ showLocationsInMenu && (
					<li className={styleSheet.navlistitem}>
						<button 
							className={locationsOpen ? styleSheet.togglelinkactive : locationsLinkActive ? styleSheet.toggleLinkNavActive : styleSheet.togglelink} 
							onClick={(event)=>{
								event.preventDefault();
								toggleLocations();
						}}>{locationsMenuTitle}</button>
						<button aria-label="close modal" className={  locationsOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { toggleLocations(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleLocations(); }}} tabIndex={0}></button> 
						<Submenu showIf={locationsOpen} setInsuranceIsOpen={setLocationsOpen} passedClass={styleSheet.locations}>
							<div className={styleSheet.inner}>
								<div className={styleSheet.left}>
									<span className={styleSheet.heading}>Featured Location</span>
									<NearestLocation locations={locations} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} />
								</div>
								<div className={styleSheet.right}>
									<span className={styleSheet.heading}>More nearby locations</span>
									<ul className={styleSheet.list}>
										{ locations && locations.map((location, index) => {
											return (
												<li key={`lstloc-'${location.id}`} className={styleSheet.listitem}>
													<Link to={location.link} className={styleSheet.link}>
														<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
														<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
														{location.ACFLocation.menuTitle && <span className={styleSheet.altmenutitle}>{location.ACFLocation.menuTitle}</span>}
													</Link>	
												</li>
											)
										})}
									</ul>
									<WPLink link={{target: '', title: 'See all locations', url: '/locations/'}} passedClass={styleSheet.seeAll} passedIconClass={styleSheet.icon} icon={arrowGreen} />
								</div>
							</div>
						</Submenu>
					</li>
				)}
				{ showTeamInMenu && (
					<li className={styleSheet.navlistitem}>
						<button 
							className={teamOpen ? styleSheet.togglelinkactive : teamLinkActive ? styleSheet.toggleLinkNavActive : styleSheet.togglelink } 
							onClick={(event)=>{
								event.preventDefault();
								toggleTeam();
						}}>{teamMenuTitle}</button>
						<button aria-label="close modal" className={  teamOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { toggleTeam(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleTeam(); }}} tabIndex={0}></button> 
						<Submenu showIf={teamOpen} setInsuranceIsOpen={setTeamOpen} passedClass={styleSheet.team}>
							<div className={styleSheet.inner}>
								<div className={styleSheet.left}>
									<span className={styleSheet.heading}>Featured Team Member</span>
									<NearestDentist locations={locations} dentists={dentists} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} />
								</div>
								<div className={styleSheet.right}>
									<span className={styleSheet.heading}>Find more team members.</span>
									<ul className={styleSheet.list}>
										{ dentists && dentists.map((dentist, index) => {
											return (
												<li key={`lstdnt-'${dentist.id}`} className={styleSheet.listitem} style={{maxWidth: dentists.length > 12 ? '50%' : '100%' }}>
													<Link to={dentist.link} className={styleSheet.link}>
														<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
														<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: dentist.title }}></span>
													</Link>	
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</Submenu>
					</li>
				)}
				{
					links && links.map( (link, index) => {
						return <li key={`${link.link.url}`} className={styleSheet.navlistitem}><WPLink key={`extralink-${index}`} link={link.link} passedClass={styleSheet.link} passedActiveClass={styleSheet.linkNavActive} /></li>;
					})
				}
				{
					navCta && (
						<li className={isHomepage ? styleSheet.navlistitemctahome : styleSheet.navlistitemcta}>{navCta?.url.includes('schedule-online') ? <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={isHomepage ? styleSheet.homecta : styleSheet.cta} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{navCta.title}</button> : <WPLink link={navCta} passedClass={isHomepage ? styleSheet.homecta : styleSheet.cta } />}</li>
					)
				}
			</ul>
		</nav>
	);
}

export default DesktopNav;
