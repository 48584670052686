import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import FLuidImage from "../fluidImage";
import WPLink from "../wpLink";
import FixedImage from "../fixedImage";
import Submenu from "../submenu";
import MobileNav from "../mobileNav";
import DesktopNav from "../desktopNav";
import formatPhone from "../../helpers/formatPhone";

import * as styleSheet from "./index.module.scss"

const Header = ({ isHomepage = false, hideAppointmentButton = false }) => {
	const data = useStaticQuery(graphql`query HeaderQuery {
  options: wp {
    headerSection {
      ACFHeaderOptions {
        topMenuBar {
          topPhoneNumber
          appointmentLink {
            ... on WpPage {
              link
            }
            ... on WpPost {
              link
            }
            ... on WpMediaItem {
              link
            }
            ... on WpLocation {
              link
            }
            ... on WpOrthodontist {
              link
            }
            ... on WpFaq {
              link
            }
            ... on WpInsurance {
              link
            }
            ... on WpService {
              link
            }
            ... on WpReview {
              link
            }
          }
          appointmentLinkText
          disclaimerInfo
          insuranceMegamenuText
          noInsuranceText
          noInsuranceSettings {
            content {
              noInsuranceBlurb
              noInsuranceHeading
              noInsuranceLink {
                url
                title
                target
              }
            }
            list {
              noInsuranceListHeading
              noInsuranceList {
                listItem
              }
            }
          }
          selectedInsurances {
            ... on WpInsurance {
              title
              ACFInsurance {
                logo {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                    extension
                  }
                }
              }
            }
          }
        }
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 210, layout: FIXED)
              }
              publicURL
              extension
            }
          }
          showLocationsInMenu
          showServicesInMenu
          showTeamInMenu
          servicesMegamenu {
            featuredServices {
              ... on WpService {
                link
                title
                id
                ACFService {
                  briefDescription
                  serviceIcon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      publicURL
                      extension
                    }
                  }
                }
              }
            }
            servicesList {
              ... on WpService {
                link
                title
                id
                ACFService {
                  briefDescription
                  serviceIcon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      publicURL
                      extension
                    }
                  }
                }
              }
            }
          }
         servicesMenuTitle
          showTeamInMenu
          navCta {
            url
            title
            target
          }
          links {
            link {
              target
              title
              url
            }
          }
          teamMegamenu {
            featuredTeamMember {
              ... on WpOrthodontist {
                id
                title
                link
              }
            }
          }
          mobileNavLinks {
            mobileLinkItem {
              target
              title
              url
            }
          }
          locationsMenuTitle
          teamMenuTitle
        }
      }
    }
    socialSection {
      ACFSocial {
        facebookUrl
        instagramUrl
        twitterUrl
      }
    }
  }
  services: allWpService(sort: {fields: title, order: ASC}) {
     nodes {
       id
       title
       link
     }
   }
  locations: allWpLocation(sort: {fields: title, order: ASC}) {
    nodes {
      id
      title
      link
			birdeyeLocation {
				location {
					city
					address1
					address2
					zip
					state
					lat
					lng
				}
				phone
			}
      ACFLocation {
        bookMyAppointmentLinkText
        briefDescription
        menuTitle
        locationInfo {
          left {
            dentists {
              ... on WpOrthodontist {
                id
                ACFDentist {
                  briefDescription
									headshot {
										localFile {
											childImageSharp {
												gatsbyImageData(width: 280, layout: CONSTRAINED)
											}
										}
									}
                }
                title
                link
              }
            }
          }
        }
      }
    }
  }
  orthodontists: allWpOrthodontist {
    nodes {
      id
      title
      link
			ACFDentist {
				lastName
				headshot {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 280, layout: CONSTRAINED)
						}
					}
				}
			}
    }
  }
  smileIcon: file(name: {eq: "smile_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 15, layout: FIXED)
    }
  }
  paymentIcon: file(name: {eq: "payment_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 15, layout: FIXED)
    }
  }
  badgeIcon: file(name: {eq: "badge_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 15, layout: FIXED)
    }
  }
  arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  arrowGrayLocal: file(name: {eq: "arrow_gray_local"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  arrowGreen: file(name: {eq: "arrow_green_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 8, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow-right-blue"}) {
    childImageSharp {
      gatsbyImageData(width: 7, layout: FIXED)
    }
  }
  arrowRightGreen: file(name: {eq: "arrow_green"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  menuIcon: file(name: {eq: "menu_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  closeIcon: file(name: {eq: "close_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 16, layout: FIXED)
    }
  }
  phoneIcon: file(name: {eq: "phone_icon"}) {
    childImageSharp {
      gatsbyImageData(width: 15, layout: FIXED)
    }
  }
  facebook: file(name: {eq: "facebook"}) {
    childImageSharp {
      gatsbyImageData(width: 15, layout: FIXED)
    }
  }
  instagram: file(name: {eq: "instagram"}) {
    childImageSharp {
      gatsbyImageData(width: 15, layout: FIXED)
    }
  }
  twitter: file(name: {eq: "twitter"}) {
    childImageSharp {
      gatsbyImageData(width: 18, layout: FIXED)
    }
  }
  locationsPage: allWpPage(
    limit: 1
    filter: {template: {templateName: {eq: "Locations Page"}}}
  ) {
    nodes {
      link
    }
  }
  teamPage: allWpPage(
    limit: 1
    filter: {template: {templateName: {eq: "Team Page"}}}
  ) {
    nodes {
      link
    }
  }
}
`);

	const {
		options: {
			headerSection: {
				ACFHeaderOptions: {
					topMenuBar: {
						topPhoneNumber,
						appointmentLink,
						appointmentLinkText,
						insuranceMegamenuText,
						disclaimerInfo: insuranceDisclaimer,
						selectedInsurances,
						noInsuranceText,
						noInsuranceSettings: {
							content: {
								noInsuranceBlurb,
								noInsuranceHeading,
								noInsuranceLink
							},
							list: {
								noInsuranceListHeading,
								noInsuranceList
							}
						},
					},
					mainNavigationSettings
				}
			},
			socialSection: {
				ACFSocial: {
				  facebookUrl,
				  instagramUrl,
				  twitterUrl,
				}
			}
		},
    services: {
      nodes: serviceNodes
    },
		orthodontists: {
			nodes: orthodontistNodes
		},
		locations: {
			nodes: locationNodes
		},
		locationsPage,
		teamPage,
		smileIcon,
		paymentIcon,
		badgeIcon,
		arrowBlue,
    arrowRightGreen,
		menuIcon,
		closeIcon,
		arrowRedLocal,
		arrowGrayLocal,
		phoneIcon,
		facebook,
		twitter,
		instagram,
		arrowGreen
	} = data;



	const [ insuranceIsOpen, setInsuranceIsOpen ] = useState(false);
	const [ noInsuranceIsOpen, setNoInsuranceIsOpen ] = useState(false);

	const toggleLinkTarget = useRef();

	const toggleOpenSubmenu = async (event, menu) => {
		toggleLinkTarget.current = event.target;
		switch(menu){
			case 'insurance':
				if( noInsuranceIsOpen ){
					await setNoInsuranceIsOpen(false);
				}
				const insuranceCurrentSetting = insuranceIsOpen;
				await setInsuranceIsOpen(!insuranceCurrentSetting);
				break;
			case 'noinsurance':
				if( insuranceIsOpen ){
					await setInsuranceIsOpen(false);
				}
				const noInsuranceCurrentSetting = noInsuranceIsOpen;
				await setNoInsuranceIsOpen(!noInsuranceCurrentSetting);
				break;
			default:
				break;
		}
	};


	
	return (
    <header className={styleSheet.Header}>
			<div className={styleSheet.top}>
				<ul className={styleSheet.topmenu}>
					{ appointmentLink && (
						<li className={styleSheet.topmenuitemfirst}>
							<Link className={styleSheet.toggle} to={appointmentLink.link}>
								{ badgeIcon && <GatsbyImage
									image={badgeIcon.childImageSharp.gatsbyImageData}
									className={styleSheet.icon} 
									placeholder="none"
									alt='' /> }
								<div dangerouslySetInnerHTML={{__html: appointmentLinkText ? appointmentLinkText : "Make an Appointment"}}></div> 
							</Link> 
						</li>
					)}
					<li className={styleSheet.topmenuitem}>
						{ insuranceMegamenuText && (
							<button className={styleSheet.toggle} onClick={ (event) => { toggleOpenSubmenu(event, 'insurance') }}>
								{ paymentIcon && <GatsbyImage
                                    image={paymentIcon.childImageSharp.gatsbyImageData}
                                    className={styleSheet.icon}
									placeholder="none"
									alt='' /> }
								<span>{insuranceMegamenuText}</span>
							</button>
						)}
						<button aria-label="close modal" className={  insuranceIsOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { setInsuranceIsOpen(false); }} onKeyDown={(event) => { if (event.keycode === 13){ setInsuranceIsOpen(false); }}} tabIndex={0}></button> 
						<Submenu showIf={insuranceIsOpen} changeState={setInsuranceIsOpen} passedClass={styleSheet.insurances}>
							<div className={styleSheet.inner}>
								{ insuranceDisclaimer && <div className={styleSheet.disclaimer} dangerouslySetInnerHTML={{__html: insuranceDisclaimer}}></div> }
								<div className={styleSheet.top}>
									{ selectedInsurances && (
										<ul className={styleSheet.list}>
											{ selectedInsurances.map( (insurance, index) => {
												return <li className={styleSheet.item} key={`insurance-${index}`}>{insurance.title}</li>;
											})}
										</ul>
									)}
								</div>
								<div className={styleSheet.bottom}>
									{ selectedInsurances && selectedInsurances.map( (insurance, index) => {
										const image = insurance.ACFInsurance.logo;
										return image ? <FLuidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
									})}
								</div>
							</div>
						</Submenu>
					</li>
					<li className={styleSheet.topmenuitemlast}>
						{ noInsuranceText && (
							<button className={styleSheet.toggle} onClick={ (event) => { toggleOpenSubmenu(event, 'noinsurance') }}>
								{ paymentIcon && <GatsbyImage
                                    image={smileIcon.childImageSharp.gatsbyImageData}
                                    className={styleSheet.icon}
									placeholder="none"
									alt='' /> }
								<span>{noInsuranceText}</span>
							</button>
						)}
						<button aria-label="close modal" className={  noInsuranceIsOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { setNoInsuranceIsOpen(false); }} onKeyDown={(event) => { if (event.keycode === 13){ setNoInsuranceIsOpen(false); }}} tabIndex={0}></button> 
						<Submenu showIf={noInsuranceIsOpen} changeState={setNoInsuranceIsOpen} passedClass={styleSheet.noinsurance}>
							<div className={styleSheet.inner}>
								<div className={styleSheet.left}>
									{ noInsuranceHeading && <span className={styleSheet.heading}>{noInsuranceHeading}</span> }
									{ noInsuranceBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: noInsuranceBlurb}}></div> }
									{ noInsuranceLink && <WPLink link={noInsuranceLink} passedClass={styleSheet.link} icon={arrowGreen} /> }
								</div>
								<div className={styleSheet.right}>
									{ noInsuranceListHeading && <span className={styleSheet.heading}>{noInsuranceListHeading}</span> }
									{ noInsuranceList && (
										<ul className={styleSheet.list}>
											{ noInsuranceList.map( (item, index) => {
												return <li key={`noilistitem-${index}`} className={styleSheet.listitem}><FixedImage passedClass={styleSheet.icon} image={arrowBlue} /> {item.listItem}</li>
											})}
										</ul>
									)} 
									
								</div>
							</div>
						</Submenu>
					</li>
				</ul>
				<ul className={styleSheet.toprightmenu}>
					{ topPhoneNumber && (
						<li className={styleSheet.phone}><a className={styleSheet.link} href={`tel:${topPhoneNumber}`} target="_blank" rel="noopener noreferrer"><FixedImage image={phoneIcon} passedClass={styleSheet.icon} /> {formatPhone(topPhoneNumber)}</a></li>
					)}
					{ facebookUrl && (
						<li className={styleSheet.social}><a href={facebookUrl} className={styleSheet.link} target="_blank" rel="noopener noreferrer"><FixedImage image={facebook} passedClass={styleSheet.facebook} /><span>Facebook</span></a></li>
					)}
					{ twitterUrl && (
						<li className={styleSheet.social}><a href={twitterUrl} className={styleSheet.link} target="_blank" rel="noopener noreferrer"><FixedImage image={twitter} passedClass={styleSheet.twitter} /><span>Twitter</span></a></li>
					)}
					{ instagramUrl && (
						<li className={styleSheet.social}><a href={instagramUrl} className={styleSheet.link} target="_blank" rel="noopener noreferrer"><FixedImage image={instagram} passedClass={styleSheet.instagram} /><span>Instagram</span></a></li>
					)}
				</ul>
			</div>
			<MobileNav mainNavigationSettings={mainNavigationSettings} menuIcon={menuIcon} closeIcon={closeIcon} services={serviceNodes} locations={locationNodes} dentists={orthodontistNodes} arrowBlue={arrowBlue} hideAppointmentButton={hideAppointmentButton} />
			<div className={styleSheet.stickycontainer}>
				<DesktopNav mainNavigationSettings={mainNavigationSettings} services={serviceNodes} locations={locationNodes} dentists={orthodontistNodes} arrowBlue={arrowBlue} arrowGreen={arrowRightGreen} arrowGrayLocal={arrowGrayLocal} arrowRedLocal={arrowRedLocal} locationsPage={locationsPage} teamPage={teamPage} isHomepage={isHomepage} />
			</div>
		</header>
    );
}

export default Header;
