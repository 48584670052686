import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react";
import useModalState from '../../helpers/modalState';

import * as styleSheet from "./index.module.scss"


const AppointmentModal = () => {

	const data = useStaticQuery(graphql`
		query appointmentQuery {
			closeIcon: file(relativePath: { eq: "close_icon.png" }) {
				publicURL
			}
            closeIconLight: file(relativePath: { eq: "close-icon-light.png" }) {
				publicURL
			}
            mobileImage: file(relativePath: { eq: "modal_image_mobile.png" }) {
				childImageSharp {
                    gatsbyImageData(width: 700, height:389, placeholder: NONE, layout: CONSTRAINED)
                }
			}
            desktopImage: file(relativePath: {eq: "modal_image_desktop.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 420, height:499, placeholder: NONE, layout: CONSTRAINED)
             
                }
            }
            options: wp {
                headerSection {
                    ACFHeaderOptions {
                        mainNavigationSettings {
                            mobileNavigationLogo {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 140, layout: CONSTRAINED, quality: 100)
                                    }
                                    publicURL
                                    extension
                                }
                            }
                        }
                    }
                }
            }
      mobileAppointmentPages: allWpPage(
        filter: {template: {templateName: {eq: "Mobile Appointments Page"}}, status: {eq: "publish"}}
      ) {
        nodes {
          id
          link
          title
        }
      }
		}
	`);

	const [showAppointmentModal, setShowAppointmentModal] = useModalState(); 
    const [ delayedLoad, setDelayedLoad ] = useState(false);

    const mobileBGImage = getImage(data.mobileImage);
    const desktopBGImage = getImage(data.desktopImage);
    const logo = data.options?.headerSection?.ACFHeaderOptions?.mainNavigationSettings?.mobileNavigationLogo?.localFile ? getImage(data.options.headerSection.ACFHeaderOptions.mainNavigationSettings.mobileNavigationLogo.localFile) : null;

    function enableCallCenterModalOnClose(){
        setShowAppointmentModal({
            modalOpen: showAppointmentModal.modalOpen, 
            zipcode: showAppointmentModal.zipcode,
            greyfinchId: showAppointmentModal.greyfinchId,
            showCallCenterModalOnClose: true,
            callCenterModalOpen: showAppointmentModal.callCenterModalOpen
        });
    }

    function disableCallCenterModalOnClose(){
        setShowAppointmentModal({
            modalOpen: showAppointmentModal.modalOpen, 
            zipcode: showAppointmentModal.zipcode,
            greyfinchId: showAppointmentModal.greyfinchId,
            showCallCenterModalOnClose: false,
            callCenterModalOpen: showAppointmentModal.callCenterModalOpen
        });
    }

    function enableCallCenterModal(){
        setShowAppointmentModal({
            modalOpen: showAppointmentModal.modalOpen, 
            zipcode: showAppointmentModal.zipcode,
            greyfinchId: showAppointmentModal.greyfinchId,
            showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
            callCenterModalOpen: true
        });
        setTimeout(function(){
            setDelayedLoad(true);
        }, 250);
    }

	function hideModal(){
		if( showAppointmentModal.modalOpen ){
			setShowAppointmentModal({
				modalOpen: false, 
				zipcode: null,
				greyfinchId: 981581,
                showCallCenterModalOnClose: false,
                callCenterModalOpen: false
			});
		}
	}

	function handleKeyDown(e) {
		if (e.keyCode === 27) {
			hideModal()
		}
	}

    function trackPhoneClick(event) {
        event.preventDefault();
        var targ = event.target.href;
        if (typeof window.ga === 'function') {
            window.ga('send', 'event', 'Call Center Phone Number', "Clicked");
            disableCallCenterModalOnClose();
            window.open(targ);
        } else if (typeof window.gtag === 'function') {
            window.ga('send', 'event', 'Call Center Phone Number', "Clicked");
            disableCallCenterModalOnClose();
            window.open(targ);
        } else {
            window.open(targ);
        }
    }

	useEffect(() => {
		var greyfinchAppointmentWidgetIframe = document.querySelector('iframe[src*="https://appointments.greyfinch.com"]');    	
		window.addEventListener('message', function(event) {
			try {
				var data = JSON.parse(event.data);
				if (data.greyfinch) {
					if (typeof window.ga === 'function') {
						window.ga('send', 'event', data.type, data.event);
					} else if (typeof window.gtag === 'function') {
						window.gtag('event', data.event, { event_category: data.type });
					}
				}

				if (data.greyfinch && data.type === 'greyfinch/appointments/resize') {
					greyfinchAppointmentWidgetIframe.style.height = data.payload.height + 'px';
				}
			} catch(error) {}
		}); 

	}, []);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown, false);
	
		return () => {
		  document.removeEventListener("keydown", handleKeyDown, false);
		};
	});

    useEffect(() => {
        setTimeout(()=>{
            enableCallCenterModalOnClose();
            console.log("call center modal enabled");
        }, 500);
	}, []);

  useEffect(()=>{
    if( showAppointmentModal.modalOpen === true ){
      if( (typeof window !== 'undefined') && (window.innerWidth < 600) && (data.mobileAppointmentPages.nodes.length > 0) ){
        setShowAppointmentModal({
          modalOpen: false, 
          zipcode: showAppointmentModal.zipcode,
          greyfinchId: showAppointmentModal.greyfinchId,
          showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
          callCenterModalOpen: showAppointmentModal.callCenterModalOpen
        });
        window.location = data.mobileAppointmentPages.nodes[0].link;
      }
    }
  }, [showAppointmentModal.modalOpen])


	let iframeSrc = "https://appointments.greyfinch.com/?division=108377&shrink=true";
	iframeSrc += showAppointmentModal.zipcode ? `&zip=${showAppointmentModal.zipcode}` : "";

	return (
		<>
            { !showAppointmentModal.callCenterModalOpen && (
                <div className={styleSheet.appointmentmodal}>
                    <div className={styleSheet.appointmentmodal__inner}>
                        <button onClick={showAppointmentModal.showCallCenterModalOnClose ? enableCallCenterModal : hideModal} className={styleSheet.close}><img src={data.closeIcon.publicURL} alt="close" width="32" height="32" loading="lazy" /><span className="sr-only">close popup modal</span></button>
                        <iframe className={styleSheet.greyfinchIframe} src={iframeSrc} allow="geolocation" width="100%" title="Grey Finch Appointment App"></iframe>
                    </div>
                </div>
            )}
            { showAppointmentModal.callCenterModalOpen && (
                <div className={styleSheet.callcentermodal}>
                    <div className={delayedLoad ? `${styleSheet.callcentermodal__inner}  ${styleSheet.callcentermodal__innerloaded}` : styleSheet.callcentermodal__inner}>
                        <div className={styleSheet.callcentermodal__left}>
                            <GatsbyImage className={styleSheet.callcentermodal__bg} image={mobileBGImage} alt="" />
                            <GatsbyImage className={styleSheet.callcentermodal__desktopbg} image={desktopBGImage} alt="" />
                            { logo && <GatsbyImage className={styleSheet.callcentermodal__logo} image={logo} alt="" />}
                        </div>
                        <div className={styleSheet.callcentermodal__right}>
                            <h1 className={styleSheet.callcentermodal__heading}>Ready to book an appointment? <br />Let us help!</h1>
                            <p className={styleSheet.callcentermodal__text}>Click below to give us a call. We can help you easily book your appointment.</p>
                            <a className={styleSheet.callcentermodal__phone} href="tel:+14176251114" target="_blank" onClick={trackPhoneClick}>Call 417-625-1114</a>
                        </div>
                        <button onClick={hideModal} className={styleSheet.close}><img src={data.closeIconLight.publicURL} alt="close" width="32" height="32" loading="lazy" /><span className="sr-only">close popup modal</span></button>
                    </div>
                </div>
            )}
        </>
	);
};

export default AppointmentModal;
