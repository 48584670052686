/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby" 
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import Header from "../header";
import Footer from "../footer";
import useModalState from "../../helpers/modalState";

import SmileAssessmentModal from "../smileAssessmentModal"
import useSmileAssessmentState from "../../helpers/smileAssessmentState";

import AppointmentModal from "../appointmentModal";

import "./styles.css";

const Layout = ({ children, hideSmilesSection = false, isHomepage = false, hideAppointmentButton = false }) => {

    const [showAppointmentModal] = useModalState();
    const [showSmileAssessmentModal] = useSmileAssessmentState();

	const {
        wp: { seo },
        footerSmiles
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
						orthodontist {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
						location {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
						service {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
            footerSmiles: allWpSmile {
                nodes {
                  id
                }
            }
        }
    `);

  	return (
		<SEOContext.Provider value={{ global: seo }}>
			<Header isHomepage={isHomepage} hideAppointmentButton={hideAppointmentButton} />
			<main>{children}</main>
			<Footer hideSmilesSection={hideSmilesSection} allSmiles={footerSmiles} />
            { showAppointmentModal.modalOpen ? <AppointmentModal /> : null }
            { showSmileAssessmentModal.smileModalOpen ? <SmileAssessmentModal/> : null }
		</SEOContext.Provider>
  	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
