import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import SmileGallerySection from "../smileGallerySection"
import FluidImage from "../fluidImage";
import FixedImage from "../fixedImage";
import WPLink from "../wpLink";
import useModalState from "../../helpers/modalState";

import * as styleSheet from "./index.module.scss"

const Footer = ({hideSmilesSection, allSmiles = null}) => {

  const [showAppointmentModal, setShowAppointmentModal] = useModalState();

  function showModal(){
		if( !showAppointmentModal.modalOpen ){
            setShowAppointmentModal({
                modalOpen: true, 
                zipcode: showAppointmentModal.zipcode,
                greyfinchId: showAppointmentModal.greyfinchId,
                showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                callCenterModalOpen: showAppointmentModal.callCenterModalOpen
            });
		}
	}

	const data = useStaticQuery(graphql`query FooterQuery {
  options: wp {
    socialSection {
      ACFSocial {
        facebookUrl
        instagramUrl
        twitterUrl
      }
    }
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          servicesMegamenu {
            servicesList {
              ... on WpService {
                link
                title
                id
                ACFService {
                  briefDescription
                  serviceIcon {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      publicURL
                      extension
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footerSection {
      	ACFFooterOptions {
			footerBackgroundImageMobile: footerBackgroundImage {
				altText
				localFile {
					childImageSharp {
					gatsbyImageData(width: 699, layout: CONSTRAINED)
					}
					publicURL
					extension
				}
			}
			footerBackgroundImageTablet: footerBackgroundImage {
				altText
				localFile {
					childImageSharp {
					gatsbyImageData(width: 999, layout: CONSTRAINED)
					}
					publicURL
					extension
				}
			}
			footerBackgroundImageDesktop: footerBackgroundImage {
				altText
				localFile {
					childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
					}
					publicURL
					extension
				}
			}
			footerBackgroundImageXL: footerBackgroundImage {
				altText
				localFile {
					childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
					}
					publicURL
					extension
				}
			}
			footerCalloutBlurb
			footerCalloutHeading
			footerAppointmentLinkText
			footerCalloutLogo {
				altText
				localFile {
					childImageSharp {
					gatsbyImageData(width: 289, layout: CONSTRAINED, quality: 100)
					}
					publicURL
					extension
				}
			}
			footerCalloutLogoMobile {
				altText
				localFile {
					childImageSharp {
					gatsbyImageData(width: 289, layout: CONSTRAINED, quality: 100)
					}
					publicURL
					extension
				}
			}
			footerMenu1 {
				link {
					target
					title
					url
				}
			}
			footerMenu {
				link {
					target
					title
					url
				}
			}
			copyrightText
			footerServicesTitle
      	}
    }
  }
  services: allWpService(sort: {fields: title}) {
    nodes {
      title
      link
      id
    }
  }
  facebook: file(name: {eq: "facebook"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  instagram: file(name: {eq: "instagram"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  twitter: file(name: {eq: "twitter"}) {
    childImageSharp {
      gatsbyImageData(width: 24, layout: FIXED)
    }
  }
}
`);

	const {
		options: {
      headerSection: {
        ACFHeaderOptions: {
          mainNavigationSettings: {
            servicesMegamenu: {
              servicesList
            }
          }
        }
      },
			footerSection: {
				ACFFooterOptions: {
					footerBackgroundImageMobile,
					footerBackgroundImageTablet,
					footerBackgroundImageDesktop,
					footerBackgroundImageXL,
					footerCalloutBlurb,
					footerCalloutHeading,
					footerAppointmentLinkText,
					footerCalloutLogo,
					footerCalloutLogoMobile,
          			footerServicesTitle,
					footerMenu1,
					footerMenu,
					copyrightText,
				}
			},
			socialSection: {
				ACFSocial: {
					facebookUrl,
					instagramUrl,
					twitterUrl,
				}
			}
		},
		services: {
			nodes: services
		},
		facebook,
		instagram,
		twitter
	} = data;

	return (
		<footer className={styleSheet.Footer}>
      {!hideSmilesSection && <SmileGallerySection allSmiles={allSmiles} />}
			<div className={styleSheet.inner}>
				{ footerBackgroundImageMobile && <FluidImage passedClass={styleSheet.bgmobile} image={footerBackgroundImageMobile} /> }
				{ footerBackgroundImageTablet && <FluidImage passedClass={styleSheet.bgtablet} image={footerBackgroundImageTablet} /> }
				{ footerBackgroundImageDesktop && <FluidImage passedClass={styleSheet.bgdesktop} image={footerBackgroundImageDesktop} /> }
				{ footerBackgroundImageXL && <FluidImage passedClass={styleSheet.bgxl} image={footerBackgroundImageXL} /> }
				
				<div className={styleSheet.callout} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
					<div className={styleSheet.top}>
						{ footerCalloutLogo && <Link to={'/'}><FluidImage image={footerCalloutLogo} passedClass={styleSheet.logo} /></Link> }
						{ footerCalloutLogoMobile && <Link to={'/'}><FluidImage image={footerCalloutLogoMobile} passedClass={styleSheet.logomobile} /></Link> }
						{ (facebookUrl || instagramUrl || twitterUrl) && (
							<div className={styleSheet.social}>
								{ facebookUrl && <a href={facebookUrl} target="_blank" rel="noopener noreferrer"><span>Facebook</span><FixedImage image={facebook} passedClass={styleSheet.fb} /></a> }
								{ twitterUrl && <a href={twitterUrl} target="_blank" rel="noopener noreferrer"><span>Twitter</span><FixedImage image={twitter} passedClass={styleSheet.twitter} /></a> }
								{ instagramUrl && <a href={instagramUrl} target="_blank" rel="noopener noreferrer"><span>Instagram</span><FixedImage image={instagram} passedClass={styleSheet.twitter} /></a> }
							</div>
						) }
					</div>
					<div className={styleSheet.bottom}>
						{ footerCalloutHeading && <span className={styleSheet.heading} dangerouslySetInnerHTML={{__html: footerCalloutHeading}}></span> }
						{ footerCalloutBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: footerCalloutBlurb}}></div> }

            { footerAppointmentLinkText && <button onClick={showModal} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{footerAppointmentLinkText}</button>}

					</div>
				</div>
				<div className={styleSheet.menus}>
					{ services.length > 0 && ( 
				  	<div className={styleSheet.services}>
                <ul className={styleSheet.list}>
                  { footerServicesTitle ? <li className={`${styleSheet.listitemmain} ${styleSheet.desktoponly}`}><span className={styleSheet.link}>{footerServicesTitle}</span></li> : <li className={styleSheet.listitemmain}><span className={styleSheet.link}>Treatments</span></li>}
                  { servicesList ? servicesList.map( (service, _index) => {
                    return (
                      <li key={service.id} className={styleSheet.listitemsecondary}>
                        <Link to={service.link} className={styleSheet.link}>
                          {service.title}
                        </Link>
                      </li>
                    );
                  }) : servicesList.map( (service, _index) => {
                    return (
                      <li key={service.id} className={styleSheet.listitemsecondary}>
                        <Link to={service.link} className={styleSheet.link}>
                          {service.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
					  </div>
					)}

		{ footerMenu1 && (
            <div className={styleSheet.footermenu}>
              <nav className={styleSheet.nav}>
                <ul className={styleSheet.list}>
                  { footerMenu1.map( (menuitem, index) => {
                    return (
                      <li key={index} className={styleSheet.listitemmain}>
                        <WPLink link={menuitem.link} passedClass={styleSheet.link} />
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          )}
   
          { footerMenu && (
            <div className={styleSheet.footermenu}>
              <nav className={styleSheet.nav}>
                <ul className={styleSheet.list}>
                  { footerMenu.map( (menuitem, index) => {
                    return (
                      <li key={index} className={styleSheet.listitemmain}>
                        <WPLink link={menuitem.link} passedClass={styleSheet.link}/>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          )}
				</div>
				<div className={styleSheet.copyright}>{new Date().getFullYear()} {copyrightText ? copyrightText : " © All Rights Reserved" }</div>
			</div>
		</footer>
	);
};

export default Footer;
