import React, { useEffect, useContext, useState } from "react";
import { Link } from 'gatsby';
import FluidImage from "../fluidImage";
import FixedImage from "../fixedImage";
import ThemeContext from '../../context/ThemeContext';
import useModalState from '../../helpers/modalState';

import * as styleSheet from "./index.module.scss"


const NearestDentist = ({locations, dentists, arrowGrayLocal, arrowRedLocal}) => {
	const [showAppointmentModal, setShowAppointmentModal] = useModalState();
  
	function showModal(){
		  if( !showAppointmentModal.modalOpen ){
            setShowAppointmentModal({
                modalOpen: true, 
                zipcode: showAppointmentModal.zipcode,
                greyfinchId: showAppointmentModal.greyfinchId,
                showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                callCenterModalOpen: showAppointmentModal.callCenterModalOpen
            });
		  }
	  }
	function handleApptKeyDown(e) {
	  if (e.keyCode === 13 || e.keyCode === 32) {
        setShowAppointmentModal({
            modalOpen: true, 
            zipcode: showAppointmentModal.zipcode,
            greyfinchId: showAppointmentModal.greyfinchId,
            showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
            callCenterModalOpen: showAppointmentModal.callCenterModalOpen
        });
		  }
	}
	
	
	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestDoc, setNearestDoc ] = useState(null);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

			setNearestLoc(locationList[0]);
			
			if( locationList[0].ACFLocation.locationInfo.left.dentists.length > 0 ){
				setNearestDoc(locationList[0].ACFLocation.locationInfo.left.dentists[0]);
			} else {
				setNearestLoc(null);
				setNearestDoc(dentists[0]);
			}

			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		if( location ){
			sortByDistance();
		} 
	}, [location, locations, dentists, getLocation])

	useEffect( () => {
		getLocation();
	}, [getLocation] )

  	return (
		<div className={styleSheet.NearestDentist}>
			{ nearestDoc === null && (
				<div className={styleSheet.right}>
					<div className={styleSheet.top}>
						<span className={styleSheet.title}>Loading...</span>
					</div>
				</div>
			)}
			{
				nearestDoc !== null && (
					<>
						<div className={styleSheet.left}>
							{nearestDoc.ACFDentist && nearestDoc.ACFDentist.headshot && (
								<FluidImage image={nearestDoc.ACFDentist.headshot} passedClass={styleSheet.image} />
							)}
						</div>
						<div className={styleSheet.right}>
							<div className={styleSheet.top}>
								<Link to={nearestDoc.link} className={styleSheet.title} dangerouslySetInnerHTML={{__html: nearestDoc.title}}></Link>
								{ nearestDoc && nearestDoc.ACFDentist && nearestDoc.ACFDentist.briefDescription && <p dangerouslySetInnerHTML={{__html: nearestDoc.ACFDentist.briefDescription.length > 100 ? nearestDoc.ACFDentist.briefDescription.substring(0, 99) + "..." : nearestDoc.ACFDentist.briefDescription }}></p>}
								<Link to={nearestDoc.link} className={styleSheet.learnmore}>Learn more <span className={styleSheet.sronly}>about {nearestDoc.title}</span><FixedImage passedClass={styleSheet.icon} image={arrowGrayLocal} /></Link>
							</div>
							<div className={styleSheet.bottom}>
								{ nearestLoc && <Link className={styleSheet.locationlink} to={nearestLoc.link} dangerouslySetInnerHTML={{__html: nearestLoc.title}}></Link> }								
								{ nearestLoc.ACFLocation.bookMyAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{nearestLoc.ACFLocation.bookMyAppointmentLinkText} <FixedImage image={arrowRedLocal} passedClass={styleSheet.icon} /></button> }
								{ !nearestLoc.ACFLocation.bookMyAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>Make an appointment <FixedImage image={arrowGrayLocal} passedClass={styleSheet.icon} /></button> }
							</div>
						</div>
					</>
				)
			}
		</div>
  	)
}

export default NearestDentist;
