import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import FixedImage from "../fixedImage";
import FLuidImage from "../fluidImage";
import WPLink from "../wpLink";
import Submenu from "../submenu";

import * as styleSheet from "./index.module.scss"


const ModalLink = ({ modal, link, passedClass = styleSheet,  icon = null }) => {

	const data = useStaticQuery(graphql`{
  options: wp {
    headerSection {
      ACFHeaderOptions {
        topMenuBar {
          disclaimerInfo
          insuranceMegamenuText
          noInsuranceText
          noInsuranceSettings {
            content {
              noInsuranceBlurb
              noInsuranceHeading
              noInsuranceLink {
                url
                title
                target
              }
            }
            list {
              noInsuranceListHeading
              noInsuranceList {
                listItem
              }
            }
          }
          selectedInsurances {
            ... on WpInsurance {
              title
              ACFInsurance {
                logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                    extension
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  arrowGreen: file(name: {eq: "arrow_green_offset"}) {
    childImageSharp {
      gatsbyImageData(width: 8, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
}
`);

	const {
		options: {
			headerSection: {
				ACFHeaderOptions: {
					topMenuBar: {
						disclaimerInfo: insuranceDisclaimer,
						selectedInsurances,
						noInsuranceSettings: {
							content: {
								noInsuranceBlurb,
								noInsuranceHeading,
								noInsuranceLink
							},
							list: {
								noInsuranceListHeading,
								noInsuranceList
							}
						},
					},
				}
			},
		},
		arrowGreen,
		arrowBlue
	} = data;

	const [ modalIsOpen, setModalIsOpen ] = useState(false);

	const toggleModal = () => {
		const toggled = !modalIsOpen;
		setModalIsOpen(toggled);
	};
	
  	return (
		<div className={styleSheet.ModalLink}>
			{ link.title && (
				<button className={passedClass} onClick={ () => { toggleModal() }}>
					{link.title}
					{ icon && <FixedImage image={icon} passedClass={styleSheet.icon} /> }
				</button>
			)}
			<button aria-label="close modal" className={  modalIsOpen ? `${styleSheet.shroud} ${styleSheet.shroudopen}` : `${styleSheet.shroud}`}  onClick={() => { toggleModal(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleModal(); }}} tabIndex={0}></button> 
			{ modal === "/modal/insurance-modal/" && (
				<Submenu showIf={modalIsOpen} setInsuranceIsOpen={setModalIsOpen} passedClass={styleSheet.insurances} orientation="flipped">
				
						<div className={styleSheet.inner}>
							{ insuranceDisclaimer && <div className={styleSheet.disclaimer} dangerouslySetInnerHTML={{__html: insuranceDisclaimer}}></div> }
							<div className={styleSheet.top}>
								{ selectedInsurances && (
									<ul className={styleSheet.list}>
										{ selectedInsurances.map( (insurance, index) => {
											return <li className={styleSheet.item} key={`insurance-${index}`}>{insurance.title}</li>;
										})}
									</ul>
								)}
							</div>
							<div className={styleSheet.bottom}>
								{ selectedInsurances && selectedInsurances.map( (insurance, index) => {
									const image = insurance.ACFInsurance.logo;
									return image ? <FLuidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
								})}
							</div>
						</div>
				</Submenu>
			)}
			{ modal === "/modal/no-insurance-modal/" && (
				<Submenu showIf={modalIsOpen} setInsuranceIsOpen={setModalIsOpen} passedClass={styleSheet.noinsurance} orientation="flipped">
					{ modal === "/modal/no-insurance-modal/" && (
						<div className={styleSheet.inner}>
							<div className={styleSheet.left}>
								{ noInsuranceHeading && <span className={styleSheet.heading}>{noInsuranceHeading}</span> }
								{ noInsuranceBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: noInsuranceBlurb}}></div> }
								{ noInsuranceLink && <WPLink link={noInsuranceLink} passedClass={styleSheet.link} icon={arrowGreen} /> }
							</div>
							<div className={styleSheet.right}>
								{ noInsuranceListHeading && <span className={styleSheet.heading}>{noInsuranceListHeading}</span> }
								{ noInsuranceList && (
									<ul className={styleSheet.list}>
										{ noInsuranceList.map( (item, index) => {
											return <li key={`noilistitem-${index}`} className={styleSheet.listitem}><FixedImage passedClass={styleSheet.icon} image={arrowBlue} /> {item.listItem}</li>
										})}
									</ul>
								)} 
								
							</div>
						</div>
					)}
				</Submenu>
			)}
		</div>
  	)
}

export default ModalLink;