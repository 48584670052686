import React, {useState} from "react";
import { useStaticQuery, graphql } from "gatsby"
import FluidImage from '../fluidImage';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css'; 

import * as styleSheet from "./index.module.scss";

export default function SmileGallerySection({allSmiles = null}) {
	

	function openLightbox(lightboxIndex, titleText, locationText){
		setcurrentLightboxImageIndex(lightboxIndex)
		setLightboxIsOpen(true)
		setCurrentLightboxText(titleText, locationText)
		setCurrentLightboxLocation(locationText)
	}

	function handleLightboxKeyDown(e, lightboxIndex, titleText, locationText){
		if (e.keyCode === 13 || e.keyCode === 32) {
			openLightbox(lightboxIndex, titleText, locationText)
		}
	}

	const [currentLightboxImageIndex, setcurrentLightboxImageIndex] = useState()
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
	const [currentLightboxText, setCurrentLightboxText] = useState('')
	const [currentLightboxLocation, setCurrentLightboxLocation] = useState('')

	const [smileImageNumbers, setSmileImageNumbers] = useState([])
	const [haveSmileImageNumbersGenerated, setHaveSmileImageNumbersGenerated] = useState(false)



	function getRandomInt(max, slice) {
		var n = Math.floor(Math.random() * Math.floor(max)) - slice;
		return n < 0 ? 0 : n; 
	}


	// console.log('all smiles')
  	// console.log(allSmiles)



	const allSmilePostIDs = allSmiles?.nodes ? allSmiles.nodes : null

	var numberOfRecordsInTable = allSmilePostIDs.length
	var numberOfRecordsForFirstArg = 3

	let smileIDsToQuery = []

	while(smileIDsToQuery.length < 3) {
		const randomNumber = getRandomInt(numberOfRecordsInTable, numberOfRecordsForFirstArg)
		if(!smileIDsToQuery.includes(allSmilePostIDs[randomNumber])) {
			smileIDsToQuery.push(allSmilePostIDs[randomNumber])
		}
	}


	const data = useStaticQuery(graphql`
    query FooterSmilesQuery($smileIDsToQuery: [String]) {
		footerSmiles: allWpSmile(
			filter: {id: {in: $smileIDsToQuery}}
		  	sort: {fields: id}
		){
			nodes {
				id
				smiles {

					smileImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(width: 699)
							}
						}
					}
				}
			}	
		}
		options: wp {
			footerSmilesSection {
				ACFFooterSmilesOptions {
					footerSmilesText {
						footerSmilesHeading
						blurb
					}
			  	}
			}
		}
    }
  `)


	const {
		footerSmiles: smiles,
		options: {
			footerSmilesSection: {
				ACFFooterSmilesOptions: {
					footerSmilesText: {
						footerSmilesHeading,
						blurb
					}
				}
			}
		}
	} = data

	function generateRandomNumber(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included 
	  }
	  const numberOfSmiles = smiles.nodes.length
	  let smileNumbersToShow = []
	
	  if(!haveSmileImageNumbersGenerated) {
		while(smileNumbersToShow.length < 3){
			var randomNumber = generateRandomNumber(0, numberOfSmiles - 1);
			if(smileNumbersToShow.indexOf(randomNumber) === -1) smileNumbersToShow.push(randomNumber);
		}
		setSmileImageNumbers(smileNumbersToShow)
		setHaveSmileImageNumbersGenerated(true)
	}

	let lightboxSmileImages = []
	smiles.nodes.forEach(smile => {
		if(smile.smiles.smileImage?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
			lightboxSmileImages.push(smile.smiles.smileImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src)
		}
	});
		
	return (
		<section className={styleSheet.smileexamples}>
			<div className={styleSheet.smileexampleswrap}>

				<div className={styleSheet.left}>
					<div className={styleSheet.sectiontext}>
						{footerSmilesHeading && <h3 className={styleSheet.sectionheading}>{footerSmilesHeading}</h3>}
						{blurb && <div className={styleSheet.blurb}>{blurb}</div>}
					</div>
				</div>

				<div className={styleSheet.right}>
					{smiles && <ul className={styleSheet.smileslist}>
						{smiles.nodes.map( (smile, index) => {
							const smilePost = smile.smiles;
								
							if(smileImageNumbers.includes(index)) {
								return (
									<li key={smile.id} className={styleSheet.smileitem}>
										<button className={styleSheet.lightboxtarget}  onKeyDown={(e) => handleLightboxKeyDown(e, index, "", "")} onClick={() => openLightbox(index, "", "")}>
											{smilePost.smileImage && <FluidImage image={smilePost.smileImage} passedClass={styleSheet.smileimage} loading={"lazy"} />} 
										</button>
									</li>
								);
							} else {
								return null;
							}
						})}
					</ul>}
				</div>

			</div>

			{lightboxIsOpen && (
				<Lightbox 
				mainSrc={lightboxSmileImages[currentLightboxImageIndex]} 
				onCloseRequest={() => setLightboxIsOpen(false)} 
				imageTitle={currentLightboxLocation}
				imageCaption={currentLightboxText}
				enableZoom={false}
				/>
            )}
		</section>
	)
}

